import jwtDecode from 'jwt-decode';
import { getCookie } from './cookies-service';
import { User as UserModel } from '../models/user-model';
import { VueMock } from 'vue-mock';
import { App } from 'vue';

export const getUserState = (): UserModel => {
  const token = getCookie('token');
  if (!token) {
    return null;
  }

  const decodedToken = jwtDecode(token);

  return decodedToken as UserModel;
};

export const UserPlugin = {
  install(Vue: VueMock) {
    Vue.prototype.getLoggedUser = getUserState;
  },
};

export const UserPluginVue3 = {
  install(app: App<Element>) {
    app.provide('$getLoggedUser', getUserState);
  },
};
