import { io, Socket } from 'socket.io-client';
import { SocketIoClientOptionsModel } from '../models/socket-io-client-options-model';
import { VueMock } from 'vue-mock';
import { App } from 'vue';

const DEFAULT_TIMEOUT_MS = 10000;

export const configureWebSocket = (options: SocketIoClientOptionsModel): Socket => {
  const { url, timeoutMs, configValues, onConnect, onDisconnect, onConnectError } = options;
  console.log('🚧 configValues', configValues);
  const socket = io(url, configValues);
  const connectErrorDefault = () =>
    setTimeout(() => socket.connect(), timeoutMs || DEFAULT_TIMEOUT_MS);

  socket.on('connect', onConnect ? onConnect : () => {});
  socket.on('disconnect', onDisconnect ? onDisconnect : () => {});
  socket.on('connect_error', onConnectError ? onConnectError : connectErrorDefault);

  return socket;
};

export const SocketIoClientPlugin = {
  install(Vue: VueMock, options: SocketIoClientOptionsModel) {
    Vue.prototype.$socket = configureWebSocket(options);

    Vue.mixin({
      created() {},
    });
  },
};

export const SocketIoClientPluginVue3 = {
  install(app: App<Element>, options: SocketIoClientOptionsModel) {
    app.provide('$socket', configureWebSocket(options));
  },
};
