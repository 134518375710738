import { getCookie } from './cookies-service';
import { navigateToUrl } from 'single-spa';
import { MenuRouteModel } from '../models/menu-route-model';
import { emitCustomEvent, listenCustomEvent } from './custom-events-service';
import { getUserState } from './user-service';
import type { NavigationGuardNext, Route, VueRouter } from 'vue-router';
import { User } from '../models/user-model';

export const noPermissionPath = 'usuario-sem-permissao';

export const dispatchRoutesMenu = (routes: MenuRouteModel[]) => {
  emitCustomEvent('@utils-service/routeMenu', { details: routes });
};

export const getRoutesMenu = (eventName: string, callback: EventListener) => {
  listenCustomEvent(eventName, callback);
};

export const goToRoute = (path: string) => {
  navigateToUrl(path);
};

interface LastRouteStorage {
  route: string;
  expiresIn: number;
}

export const setLastRoute = (fullRoute: string, minutesToExpire: number): void => {
  const minutesToMs = minutesToExpire * 60 * 1000;

  const saveRoute: LastRouteStorage = {
    route: fullRoute,
    expiresIn: new Date().getTime() + minutesToMs,
  };

  localStorage.setItem('last_route', JSON.stringify(saveRoute));
};

const isValidRouteTime = (routeStorage: LastRouteStorage): boolean => {
  return new Date().getTime() <= routeStorage?.expiresIn;
};

const clearLastRoute = (): void => {
  localStorage.removeItem('last_route');
};

export const getLastRoute = (): string => {
  if (!localStorage.getItem('last_route')) {
    return;
  }

  const lastRouteStorage: LastRouteStorage = JSON.parse(
    localStorage.getItem('last_route'),
  ) as LastRouteStorage;

  if (isValidRouteTime(lastRouteStorage)) {
    return lastRouteStorage.route;
  }

  clearLastRoute();
  return null;
};

const isAuthenticated = (): boolean =>
  getCookie('token') != null && getCookie('refreshToken') != null;
const isLoginRoute = (baseUrl: string, fullPath: string): boolean => {
  if (baseUrl.startsWith('/login') || fullPath?.startsWith('/login')) {
    return true;
  }

  if (!baseUrl?.startsWith('/login') || !fullPath?.startsWith('/login')) {
    return false;
  }
};

const hasToRedirect = (baseUrl: string, fullPath: string, user: User): boolean =>
  user?.clientId == null &&
  ((baseUrl?.startsWith('/workspace') && !fullPath?.startsWith('/selecionar-empresa')) ||
    !baseUrl?.startsWith('/workspace'));

const redirectPortalRoute = (baseUrl: string, user: User, next: NavigationGuardNext) => {
  const selectClientPath: string = '/selecionar-empresa?hideHeader=true&hideMenu=true';
  const selectAppPath: string = '/selecionar-aplicacao?hideHeader=true&hideMenu=true';
  const newPath = user?.clientId ? selectAppPath : selectClientPath;

  if (baseUrl.startsWith('/workspace')) {
    return next({ path: newPath });
  }

  navigateToUrl(`/workspace${newPath}`);
  next();
};

export const configureBeforeEachRouter = (
  router: VueRouter,
  to: Route,
  from: Route,
  next: NavigationGuardNext,
): void => {
  /* Ao finalizar migração do vue 2 para o 3, `router?.options?.base` não será mais necessário */
  const baseUrl: string = router?.options?.base || router?.options?.history?.base;
  const fullPath: string = to?.fullPath;

  if (!isAuthenticated()) {
    if (!isLoginRoute(baseUrl, fullPath)) {
      setLastRoute(`${baseUrl === '/' ? '' : baseUrl}${to?.path}`, 5);
    }

    return navigateToUrl('/login');
  }

  if (isAuthenticated()) {
    const user = getUserState();
    const lastRoute: string = getLastRoute();

    if (isLoginRoute(baseUrl, fullPath)) {
      return redirectPortalRoute(baseUrl, user, next);
    }

    if (user?.clientId && lastRoute) {
      clearLastRoute();
      return navigateToUrl(lastRoute);
    }

    if (hasToRedirect(baseUrl, fullPath, user)) {
      return redirectPortalRoute(baseUrl, user, next);
    }
  }

  next();
};
