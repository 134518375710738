import Cookies from 'js-cookie';

export const getCookie = (name: string): string | null => {
  const value = Cookies.get(name);
  if (value !== null && value?.length > 0) {
    return value;
  }

  return null;
};

export const setCookie = (name: string, value: string, expires?: number, path?: string) => {
  let attributes = {};
  if (expires !== undefined && expires > 0) {
    attributes = { ...attributes, expires };
  }

  if (path !== undefined && path?.length > 0) {
    attributes = { ...attributes, path };
  }

  return Cookies.set(name, value, attributes);
};

export const removeCookie = (name: string, path?: string) => {
  return Cookies.remove(name, { path: path ?? '/' });
};
