import axios, { AxiosInstance, AxiosError, Axios } from 'axios';
import { VueRouter } from 'vue-router';
import { navigateToUrl } from 'single-spa';

import { getCookie, removeCookie, setCookie } from './cookies-service';
import { setLastRoute } from './router-service';
import { defineHttpInterceptors, generateAxiosInstance } from './interceptors-service';

const { getBackendDomain } = require('@zitrus/z-login-utils');

type FailedRequestQueue = {
  onSuccess: (axios: Axios, newToken: string) => void;
  onFailure: (error: AxiosError, isSilentAbort?: boolean) => void;
};

/*
 * Array for queue the request on refresh token is over array.
 *
 * @type {FailedRequestQueue[]}
 */
let failedRequestsQueue: FailedRequestQueue[] = [];

/*
 * Control when is need to refresh the page.
 *
 * @type {boolean}
 */
let isRefreshing = false;

const addRequestQueue = (request: FailedRequestQueue) => {
  failedRequestsQueue.push(request);
};

const clearFailedRequestsQueue = () => {
  failedRequestsQueue = [];
};

/*
 * Execute the routine for when the call for refresh token has a failure.
 *
 * @param {AxiosError} error
 */
const onRefreshTokenError = (error: AxiosError): void => {
  removeCookie('token');
  removeCookie('refreshToken');

  let isSilentAbort = false;

  failedRequestsQueue.forEach((request) => {
    request.onFailure(error, isSilentAbort);

    if (!isSilentAbort) {
      isSilentAbort = true;
    }
  });

  clearFailedRequestsQueue();
};

/*
 * Send a request o backend to try to refresh token and execute the routines for success or failure.
 *
 * @param {Axios} clientAxios
 * @param {string} refreshToken
 * @param {VueRouter} router?
 */
const refreshTokenAPI = async (
  clientAxios: Axios,
  refreshToken: string,
  router?: VueRouter,
): Promise<void> => {
  const axiosRefresh = generateAxiosInstance(axios, getBackendDomain());
  isRefreshing = true;

  defineHttpInterceptors(axiosRefresh, router);

  try {
    const url = '/login/refreshtoken';
    const { data } = await axiosRefresh.post(url, { refreshToken });

    setCookie('token', data?.accessToken);
    setCookie('refreshToken', data?.refreshToken);

    failedRequestsQueue.forEach((request) => request.onSuccess(clientAxios, data?.accessToken));

    clearFailedRequestsQueue();
  } catch (error) {
    onRefreshTokenError(error);
  }

  isRefreshing = false;
};

/*
 * Try to refresh the user token, by sending a request to z-login service.
 * After, put the 401 requests on a queue and re-send then, with the new user token.
 *
 * @param {string} refreshToken
 * @param {Axios} axios
 * @param {AxiosError} error
 * @param {VueRouter} router?
 */
const refreshTokenOnResponseError = async (
  refreshToken: string,
  axios: Axios,
  error: AxiosError,
  router?: VueRouter,
) => {
  const originalRequest = Object.assign({}, error.config);

  if (!isRefreshing) {
    await refreshTokenAPI(axios, refreshToken, router);
  }

  return new Promise((resolve, reject) => {
    addRequestQueue({
      onSuccess: async (axiosRequest: AxiosInstance, newToken: string) => {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return resolve(axiosRequest(originalRequest));
      },
      onFailure: (error: AxiosError, isSilentAbort: boolean) => {
        if (isSilentAbort) {
          return;
        }

        return reject(error);
      },
    });
  });
};

/*
 * Control the 401 error from API, and execute the routine for refresh the user token.
 *
 * @param {AxiosError} error
 * @param {Axios} axios
 * @param {VueRouter} router?
 */
const responseError401 = async (error: AxiosError, axios: Axios, router?: VueRouter) => {
  const refreshToken = getCookie('refreshToken');
  const isTokenExpired = error?.response?.data['detail']?.toLowerCase()?.includes('jwt expired');
  const isLoginRoute = router?.currentRoute?.fullPath === '/login';

  if (isLoginRoute) {
    return Promise.reject(error);
  }

  if (isTokenExpired && refreshToken) {
    return await refreshTokenOnResponseError(refreshToken, axios, error, router);
  }

  removeCookie('token');
  removeCookie('refreshToken');

  if (router && !isLoginRoute) {
    setLastRoute(`${router?.options?.base}${router?.currentRoute?.path}`, 5);
    navigateToUrl('/login');
  }
};

export default responseError401;
