import moment from 'moment';
import { MomentInput } from 'moment/moment';

const DEFAULT_BRAZILIAN_FORMAT = 'DD/MM/YYYY';
const DEFAULT_BRAZILIAN_DATE_TIME_FORMAT = `${DEFAULT_BRAZILIAN_FORMAT} HH:mm:ss`;
const DEFAULT_BRAZILIAN_DATE_COMPETENCY = 'MM/YYYY';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT}[T]HH:mm:ss`;

const currentDate = (format = DEFAULT_BRAZILIAN_FORMAT) => moment().format(format);

const formatDate = (
  date: MomentInput,
  destinationFormat = DEFAULT_BRAZILIAN_FORMAT,
  originFormat = DEFAULT_DATE_FORMAT,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

const formatDateTime = (
  dateTime: MomentInput,
  destinationFormat = DEFAULT_BRAZILIAN_DATE_TIME_FORMAT,
  originFormat = DEFAULT_DATE_TIME_FORMAT,
) =>
  moment(dateTime, originFormat).isValid()
    ? moment(dateTime, originFormat).format(destinationFormat)
    : '';

const formatDateRestore = (date: MomentInput) =>
  formatDate(date, DEFAULT_DATE_FORMAT, DEFAULT_BRAZILIAN_FORMAT);

const getPeriodoDeTempo = (date: MomentInput) => {
  if (!date) {
    return '';
  }

  const data = moment(date);
  const dataAtual = moment();

  const anos = dataAtual.diff(data, 'years');

  data.add(anos, 'years');

  const meses = dataAtual.diff(data, 'months');

  data.add(meses, 'months');

  const dias = dataAtual.diff(data, 'days');

  return `${anos}a, ${meses}m, ${dias}d`;
};

const getTargetDateFromNumberDays = ({ numberDays = 0, format = DEFAULT_BRAZILIAN_FORMAT }) => {
  const today = moment();

  return today.add(numberDays, 'days').format(format);
};

const getTime = (date: MomentInput) => (date ? moment(date).format('HH:mm:ss') : null);

const formatDateCompetency = (
  date: MomentInput,
  originFormat = DEFAULT_DATE_FORMAT,
  destinationFormat = DEFAULT_BRAZILIAN_DATE_COMPETENCY,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

export {
  getPeriodoDeTempo,
  formatDate,
  formatDateTime,
  formatDateRestore,
  currentDate,
  getTargetDateFromNumberDays,
  DEFAULT_BRAZILIAN_FORMAT,
  DEFAULT_DATE_FORMAT,
  getTime,
  formatDateCompetency,
};
