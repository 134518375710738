import { Axios, AxiosError, AxiosInstance, AxiosStatic, InternalAxiosRequestConfig } from 'axios';
import { VueRouter } from 'vue-router';

import { getCookie } from './cookies-service';
import responseError403 from './interceptors-403-error-service';
import responseError401 from './interceptors-401-error-service';

/*
 * Object to map all the status code, for a function call.
 *
 * @type {Object}
 */
const errorActions = {
  401: responseError401,
  403: responseError403,
};

/*
 * Add token on header for the request that will be made.
 *
 * @param {AxiosRequestConfig} config
 */
const requestInterceptorConfig = (config: InternalAxiosRequestConfig) => {
  const token = getCookie('token');
  if (token !== undefined && token?.length > 0) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

/*
 * Control the response error from API, and execute the right routine for the error status code.
 *
 * @param {AxiosError} error
 * @param {Axios} axios
 * @param {VueRouter} router?
 */
const responseErrorConfig = async (error: AxiosError, axios: Axios, router?: VueRouter) => {
  const status = error?.response?.status;
  const action = errorActions[status];

  return action ? await action(error, axios, router) : Promise.reject(error);
};

export const generateAxiosInstance = (axios: AxiosStatic, baseURL: string): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export function defineHttpInterceptors(axios: Axios, router?: VueRouter) {
  axios.interceptors.request.use((config) => requestInterceptorConfig(config));

  axios.interceptors.response.use(
    (response) => response,
    async (error) => responseErrorConfig(error, axios, router),
  );
}
