export function emitCustomEvent(name: string, data) {
  dispatchEvent(
    new CustomEvent(name, {
      detail: data,
    }),
  );
}

export const listenCustomEvent = (name: string, cb: (data) => void): void => {
  window.addEventListener(name, cb);
};
